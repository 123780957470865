import noop from 'lodash/noop'
import React, {useCallback, useMemo} from 'react'

import OptimizedImage from '../../OptimizedImage'
import {LogoWrapper} from './styles'

const homeLinkObj = {
  label: 'home',
  link: '/',
}

function HeaderBrandLogo({
  brandLogoLink = '',
  altText = 'BrandLogo',
  handleMenuItemClicked = noop,
  width,
  height,
  desktopWidth,
  desktopHeight,
  isMobile,
}) {
  const handleNavigation = useCallback((e) => {
    e.preventDefault()
    handleMenuItemClicked(homeLinkObj)
  }, [])

  const [renderHeightForLogo, renderWidthForLogo] = useMemo(() => {
    if (isMobile) {
      return [height, width]
    }

    return [desktopHeight, desktopWidth]
  }, [height, width, desktopHeight, desktopWidth])

  return (
    <LogoWrapper
      href="/"
      onClick={handleNavigation}
      width={renderWidthForLogo}
      height={renderHeightForLogo}
    >
      <OptimizedImage
        source={brandLogoLink}
        aspectWidth={renderWidthForLogo}
        aspectHeight={renderHeightForLogo}
        desktopViewWidth="SMALL"
        mobileViewWidth="SMALL"
        altText={altText}
        loading="lazy"
        customClassName="logoimg"
      />
    </LogoWrapper>
  )
}

export default React.memo(HeaderBrandLogo)
