import styled from 'styled-components'

export const LogoWrapper = styled.a`
  display: flex;
  align-items: center;
  height: min(44px, calc(36px + (44 - 36) * ((100vw - 300px) / (1440 - 300))));
  width: ${({width}) => (width ? `${width}px` : 'max-content')};
  height: ${({height}) => (height ? `${height}px` : 'auto')};
  cursor: pointer;

  .logoimg {
    height: 100%;
    width: auto;
    object-fit: cover;
  }

  @media (min-width: 768px) {
    margin-left: 50px;
  }
`
