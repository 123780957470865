import styled from 'styled-components'

export const DropdownWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .dropdown-linksWrapper {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    padding-bottom: 10px;
    background-color: #fff;
    flex-direction: column;
  }

  .dropdown-linkItem {
    display: flex;
    position: relative;
    padding-bottom: 20px;
    cursor: pointer;
  }

  .label-container {
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;
    padding-bottom: 26px;
    color: var(--brand-secondary-text-color);
    font-family: var(--brand-font-family);
    font-size: 15px;
    line-height: 16px;
    font-weight: var(--brand-font-weight-semiBold);
    font-style: normal;
    cursor: pointer;
  }

  .dropdown-title {
    flex: 1 1;
    cursor: pointer;
  }

  .dropdown-arrow {
    border-left: 2px solid var(--brand-primary-black-color);
    border-bottom: 2px solid var(--brand-primary-black-color);
    width: 10px;
    height: 10px;
    transform: rotate(315deg);
    ${(props) => props.isOpen && `transform: rotate(135deg);`}
    cursor: pointer;
  }

  .dropdown-link {
    display: flex;
    width: 100%;
  }

  .dropdown-img-container {
    height: 46px;
    width: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dropdown-img {
    object-fit: contain;
    width: auto;
    height: 100%;
  }

  .dropdown-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1;
    overflow: hidden;
    padding-left: 10px;
  }

  .dropdown-label-tag {
    display: flex;
    height: 23px;
    align-items: center;
  }

  .dropdown-label {
    font-family: var(--brand-font-family);
    font-style: normal;
    font-weight: var(--brand-font-weight-medium);
    font-size: 14px;
    line-height: 16px;
    color: var(--brand-primary-black-color);
    cursor: pointer;
  }

  .tagImage {
    height: 17px;
    width: auto;
    object-fit: contain;
    margin-left: 5px;
  }

  .dropdown-desc-text-container {
    height: 34px;
    display: flex;
    align-items: center;
  }

  .dropdown-descriptionText {
    font-family: var(--brand-font-family);
    font-style: normal;
    font-weight: var(--brand-font-weight-medium);
    font-size: 12px;
    line-height: 14px;
    color: var(--brand-primary-subtext-color);
    height: 50%;
    cursor: pointer;
  }
`
