import noop from 'lodash/noop'
import {useCallback, useState} from 'react'

import {getLinkToRender} from '../../../../utils'
import OptimizedImage from '../../OptimizedImage'
import {DropdownWrapper} from './styles'

function MobileDropdown({
  dropdownItems = [],
  label = '',
  customLabel = '',
  handleMenuItemClicked = noop,
  isOpenOnMobile = false,
}) {
  const [isOpen, setIsOpen] = useState(isOpenOnMobile)

  const toggleDropdown = useCallback(() => {
    setIsOpen((isOpen) => !isOpen)
  }, [])

  const handleNavigation = useCallback((e, item) => {
    e.preventDefault()
    handleMenuItemClicked(item)
  }, [])

  return (
    <DropdownWrapper isOpen={isOpen}>
      <div className="label-container" onClick={toggleDropdown}>
        {customLabel}
        {label && <label className="dropdown-title">{label}</label>}
        <div className="dropdown-arrow" />
      </div>
      {isOpen && (
        <ul className="dropdown-linksWrapper">
          {dropdownItems.map((item) => {
            const {
              label = '',
              descriptionText = '',
              link = '',
              image = '',
              tagImage = '',
            } = item || {}
            const linkToRender = getLinkToRender(link)
            return (
              <li key={label} className="dropdown-linkItem">
                <a
                  className="dropdown-link"
                  href={linkToRender}
                  onClick={(e) => {
                    handleNavigation(e, item)
                  }}
                >
                  {!!image ? (
                    <div className="dropdown-img-container">
                      <OptimizedImage
                        source={image}
                        aspectWidth="46"
                        aspectHeight="46"
                        desktopViewWidth="TINY"
                        mobileViewWidth="TINY"
                        loading="lazy"
                        customClassName="dropdown-img"
                      />
                    </div>
                  ) : null}
                  <div className="dropdown-description">
                    <div className="dropdown-label-tag">
                      <label className="dropdown-label">{label}</label>
                      {!!tagImage && (
                        <img className="tagImage" src={tagImage} />
                      )}
                    </div>
                    {!!descriptionText && (
                      <div className="dropdown-desc-text-container">
                        <label className="dropdown-descriptionText">
                          {descriptionText}
                        </label>
                      </div>
                    )}
                  </div>
                </a>
              </li>
            )
          })}
        </ul>
      )}
    </DropdownWrapper>
  )
}

export default MobileDropdown
