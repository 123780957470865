import React from 'react'

interface SearchIconProps {
  size?: number
  strokeColor?: string
}

function SearchIcon({size = 20, strokeColor = '#666'}: SearchIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17.5 17.5l-5-5m1.667-4.167a5.833 5.833 0 11-11.667 0 5.833 5.833 0 0111.667 0z"
      ></path>
    </svg>
  )
}

export default SearchIcon
