import noop from 'lodash/noop'
import React from 'react'
import {HamburgerWarpper} from './styles'
import CloseIcon from './CloseIcon'
import MenuIcon from './MenuIcon'

const Hamburger = ({toggle = noop, isOpen, fontColor = '#000000'}) => {
  return (
    <HamburgerWarpper isOpen={isOpen} onClick={toggle} fontColor={fontColor}>
      {isOpen ? (
        <CloseIcon height={20} width={20} fontColor={fontColor} />
      ) : (
        <MenuIcon height={30} width={20} fontColor={fontColor} />
      )}
    </HamburgerWarpper>
  )
}

export default Hamburger
