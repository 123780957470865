import styled from 'styled-components'

export const DropdownWrapper = styled.div`
  flex-direction: column;
  position: absolute;
  left: 0px;
  top: 99%;
  background-color: transparent;
  display: flex;
  z-index: 3;
  display: none;

  .dropdown-linksWrapper {
    margin: 0;
    padding: 12px 0;
    border-radius: 0 0 20px 20px;
    display: flex;
    list-style: none;
    background-color: var(--brand-primary-white-color);
    min-width: max-content;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2);
    flex-direction: column;
  }

  .dropdown-linkItem {
    display: flex;
    cursor: pointer;
  }

  .dropdown-label {
    font-family: var(--brand-font-family-text);
    font-size: 16px;
    line-height: 18px;
    cursor: pointer;
    font-weight: 400;
    float: none;
    color: rgba(0, 0, 0, 0.4);
    padding: 15px 25px;
    text-decoration: none;
    display: block;
    text-align: left;
    width: 100%;
    &:hover {
      background-color: rgba(180, 180, 180, 0.1);
      color: var(--brand-primary-black-color);
    }
  }
`
