import styled from 'styled-components'

export const StyledMenu = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`
export const StyledAvatar = styled.div`
  display: inline-block;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  border: 2px solid var(--brand-primary-color);
  margin-right: 12px;
  overflow: hidden;
  .avatar-img {
    width: 23px;
    height: 23px;
  }
`
