import noop from 'lodash/noop'
import React, {useCallback} from 'react'
import {getLinkToRender} from '../../../../utils'
import {DropdownWrapper} from './styles'

function Dropdown({dropdownItems = [], handleMenuItemClicked = noop}) {
  const handleNavigation = useCallback((e, item) => {
    e.preventDefault()
    handleMenuItemClicked(item)
  }, [])
  return (
    <DropdownWrapper className="desktop-dropdown">
      <ul className="dropdown-linksWrapper">
        {dropdownItems.map((item) => {
          const {label = '', link = ''} = item
          const linkToRender = getLinkToRender(link)
          return (
            <a
              className="dropdown-linkItem"
              key={label}
              href={linkToRender}
              onClick={(e) => {
                handleNavigation(e, item)
              }}
            >
              <span className="dropdown-label">{label}</span>
            </a>
          )
        })}
      </ul>
    </DropdownWrapper>
  )
}

export default Dropdown
