import styled from 'styled-components'

export const StyledSearchBar = styled.div`
  padding-bottom: 12px;
  width: 100%;
  background-color: ${(props) => props.bgColor};
  padding-top: 5px;

  @media (min-width: 768px) {
    padding-bottom: 5px;
    height: 38px;
    padding-top: 0;
  }

  .search-bar-container {
    margin: 0 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
    justify-content: flex-start;
    border-radius: 8px;
    border: 1px solid var(--dls-divider-light-color);
    background-color: transparent;
    font-weight: var(--brand-font-weight-medium);
    outline: none;
    @media (min-width: 768px) {
      margin: 0px 8px;
      margin-top: 0;
    }
  }

  .section-left {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    padding-right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    color: var(--brand-secondary-text-color);
    .icon-search {
      width: 15px;
      height: 15px;
    }
  }

  .section-right {
    width: 100%;
    cursor: text;
    .search-placeholder-text {
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      outline: none;
      border: none;
      background-color: transparent;
      font-size: 14px;
      line-height: 14px;
      color: var(--dls-secondary-text-colors);
      width: 100%;
      @media (min-width: 768px) {
        width: 220px;
        max-width: 220px;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        span {
          width: 100%;
          white-space: nowrap;
          max-width: 100%;
          display: inline-flex;
          overflow: hidden;
        }
      }
    }
  }
`
