import noop from 'lodash/noop'
import React, {useCallback, useEffect, useMemo, useState} from 'react'

function AnimatePlaceholder({
  animationTextArr = [],
  animationTextTimelimit = 0,
  prefix = '',
  waitTime = 1000,
}) {
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0)

  const numberOfPhrases = animationTextArr.length

  const handlePhraseIndex = useCallback(() => {
    setCurrentPhraseIndex((current) =>
      current === numberOfPhrases - 1 ? 0 : current + 1
    )
  }, [numberOfPhrases])

  return (
    <div>
      {prefix} "
      <strong>
        <AnimationText
          textToAnimate={animationTextArr[currentPhraseIndex]}
          onAnimationComplete={handlePhraseIndex}
          textIncrementInterval={animationTextTimelimit}
          waitTime={waitTime}
        />
      </strong>
      "
    </div>
  )
}

const AnimationText = ({
  textToAnimate = '',
  onAnimationComplete = noop,
  textIncrementInterval = 0,
  waitTime = 1000,
}) => {
  const [phraseTextLengthToShow, setPhraseTextLengthToShow] = useState(1)
  const textLength = textToAnimate?.length
  const phraseToShow = useMemo(() => {
    return textToAnimate?.slice(0, phraseTextLengthToShow)
  }, [textToAnimate, phraseTextLengthToShow])

  // * timeout needed to promote to next word
  const timeOutForCompletion = useMemo(() => {
    return textIncrementInterval * textLength + waitTime
  }, [textIncrementInterval, textLength, waitTime])

  // logic to call next word
  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      onAnimationComplete()
    }, timeOutForCompletion)

    return () => clearTimeout(timeoutRef)
  }, [timeOutForCompletion, onAnimationComplete])

  // * reset
  useEffect(() => {
    setPhraseTextLengthToShow(0)
  }, [textToAnimate])

  // animate current text
  useEffect(() => {
    const intervalRef = setInterval(() => {
      setPhraseTextLengthToShow((current) => current + 1)
    }, textIncrementInterval)

    return () => clearInterval(intervalRef)
  }, [textIncrementInterval, phraseTextLengthToShow])

  return phraseToShow
}

export default AnimatePlaceholder
