import noop from 'lodash/noop'

import {useInternationalizationContext} from '../../../Context/InternationalizationContext'
import Responsive from '../../../HOC/Responsive'
import OptimizedImage from '../../OptimizedImage'
import SearchBar from '../SearchBar'
import {IconsMenuContainer, StyledWalletIconContainer} from './styles'
import {Typography} from '../../TypographyV2'

function IconsMenu({
  iconsMenuOptions = [],
  handleIconClicked = noop,
  cartItemCount = 0,
  user = {},
  isMobile = false,
  isSearchBarShown = false,
  whatsappData,
}) {
  const {walletPoints = 0, isLoggedIn} = user

  const {currency} = useInternationalizationContext()

  return (
    <IconsMenuContainer>
      {iconsMenuOptions.map((icon) => {
        const {
          image = '',
          tagImage = '',
          label = '',
          showInDesktop = true,
          showInMobile = true,
          link = '',
          isSearchBarWithConfigText = false,
          walletAmountBgColor = '',
          walletBorderColorMobile = '',
          walletBorderColorDesktop = '',
          walletBgColorMobile = '',
          walletBgColorDesktop = '',
          walletTextColorMobile = '',
          walletTextColorDesktop = '',
          emptyWalletText = '',
        } = icon
        if (label === 'search' && isSearchBarShown) {
          if (isMobile) {
            return null
          } else {
            return (
              <SearchBar
                handleIconClicked={handleIconClicked}
                searchBarData={icon}
                key={label}
              />
            )
          }
        }

        if (label === 'wallet' || label === 'wallet-balance') {
          if (!isLoggedIn && label === 'wallet') {
            return null
          } else if (isSearchBarWithConfigText) {
            return (
              <StyledWalletIconContainer
                isMobile={isMobile}
                walletAmountBgColor={walletAmountBgColor}
                walletBorderColorMobile={walletBorderColorMobile}
                walletBorderColorDesktop={walletBorderColorDesktop}
                walletBgColorMobile={walletBgColorMobile}
                walletBgColorDesktop={walletBgColorDesktop}
                walletTextColorMobile={walletTextColorMobile}
                walletTextColorDesktop={walletTextColorDesktop}
                onClick={() => {
                  handleIconClicked(icon)
                }}
                key={label}
              >
                <div className="icon-container" key={label}>
                  <img
                    src={image}
                    alt={label}
                    className="icon-image"
                    loading="lazy"
                  />
                </div>
                <div className="wallet-points-container">
                  <span className="wallet-points-text">
                    {currency}
                    {walletPoints}
                  </span>
                </div>
              </StyledWalletIconContainer>
            )
          }
        }
        if (label === 'whatsapp') {
          const {isHidden = true, whatsappLink = link} = whatsappData || {}
          if (isHidden) return null
          return (
            <a
              href={whatsappLink}
              key={label}
              target="_blank"
              id="whatappIconAnocher"
              rel="noreferrer"
              className="icon-container"
            >
              <OptimizedImage
                source={image}
                aspectWidth="28"
                aspectHeight="28"
                desktopViewWidth="TINY"
                mobileViewWidth="TINY"
                altText={label}
                loading="lazy"
                customClassName="icon-image"
              />
            </a>
          )
        } else if (label === 'wallet-balance') {
          const showIcon = isMobile ? showInMobile : showInDesktop
          if (showIcon) {
            return (
              <div
                onClick={() => {
                  handleIconClicked(icon)
                }}
                className="icon-container wallet-container"
                key={label}
              >
                <div
                  style={{
                    width: '18px',
                    height: '18px',
                  }}
                >
                  <OptimizedImage
                    source={image}
                    aspectWidth="28"
                    aspectHeight="28"
                    desktopViewWidth="TINY"
                    mobileViewWidth="TINY"
                    altText={label}
                    loading="lazy"
                    customClassName="icon-image"
                  />
                </div>
                <Typography variant="body-base-bold">
                  {walletPoints > 0
                    ? `${currency} ${walletPoints}`
                    : emptyWalletText}
                </Typography>
              </div>
            )
          }
        } else {
          const showIcon = isMobile ? showInMobile : showInDesktop
          if (showIcon) {
            return (
              <div className="icon-container" key={label}>
                <div
                  style={{
                    width: '24px',
                    height: '24px',
                  }}
                >
                  <OptimizedImage
                    source={image}
                    aspectWidth="28"
                    aspectHeight="28"
                    desktopViewWidth="TINY"
                    mobileViewWidth="TINY"
                    altText={label}
                    loading="lazy"
                    customClassName="icon-image"
                    onClick={() => {
                      handleIconClicked(icon)
                    }}
                  />
                </div>
                {label === 'wallet-balance' && (
                  <Typography variant="body-base-bold">
                    {walletPoints > 0
                      ? `${currency} ${walletPoints}`
                      : emptyWalletText}
                  </Typography>
                )}
                {tagImage && (
                  <span className="tag-image-wrap">
                    <OptimizedImage
                      source={tagImage}
                      aspectWidth="16"
                      aspectHeight="16"
                      desktopViewWidth="TINY"
                      mobileViewWidth="TINY"
                      altText="tag"
                      loading="lazy"
                      customClassName="tag-image"
                    />
                  </span>
                )}
                {label === 'cart' && cartItemCount > 0 && (
                  <span className="cart-badge">{cartItemCount}</span>
                )}
              </div>
            )
          }
        }
      })}
    </IconsMenuContainer>
  )
}

export default Responsive(IconsMenu)
