import noop from 'lodash/noop'
import React, {useCallback, useEffect, useState} from 'react'
import {FaSearch} from 'react-icons/fa'
import Responsive from '../../../HOC/Responsive'
import {StyledSearchBar} from './styles'
import AnimatePlaceholder from './AnimatePlaceholder'
import SearchIcon from '../../../Icons/Search'

function SearchBar({searchBarData, handleIconClicked = noop}) {
  const {
    animationText = [],
    animationTextTimelimit,
    waitTime,
    bgColor = '',
    textColor = '',
    borderColor = '',
  } = searchBarData || {}

  const memoisedHandleIconClicked = useCallback(() => {
    handleIconClicked(searchBarData)
  }, [searchBarData, handleIconClicked])

  return (
    <StyledSearchBar
      bgColor={bgColor}
      textColor={textColor}
      borderColor={borderColor}
      onClick={memoisedHandleIconClicked}
    >
      <div className="search-bar-container">
        <div className="section-left">
          <SearchIcon />
        </div>
        <div className="section-right">
          <div className="search-placeholder-text">
            <AnimatePlaceholder
              prefix="Search for"
              animationTextArr={animationText}
              animationTextTimelimit={animationTextTimelimit}
              waitTime={waitTime}
            />
          </div>
        </div>
      </div>
    </StyledSearchBar>
  )
}

export default Responsive(SearchBar)
