import styled from 'styled-components'

export const NavLinksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: inherit;
  color: inherit;
  transition: all 500ms ease-in-out;

  .navLinksWrapper {
    margin: 0;
    padding: 0;
    display: flex;
    height: 100%;
    list-style: none;
    flex: 1 1;
    flex-wrap: wrap;
  }

  .linkItems {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0rem 1rem;
    padding: 0;
    position: relative;
    flex-wrap: wrap;
    cursor: pointer;

    &.hasDropdown {
      &:hover {
        .desktop-dropdown {
          display: flex;
        }
      }
    }

    &:hover {
      .dropdown-label {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }

  .link {
    color: inherit;
    font-family: var(--brand-font-family-text);
    font-weight: var(--brand-font-weight-medium);
    cursor: pointer;
    line-height: 1.25rem;
    font-size: 1rem;
    position: relative;

    &.dropdown-label {
      margin-right: 20px;
      &:after {
        content: '';
        position: absolute;
        right: -18px;
        top: 40%;
        width: 0;
        height: 0;
        border-left: 0.35rem solid transparent;
        border-right: 0.35rem solid transparent;
        border-top: 0.35rem solid ${(props) => props.fontColor};
        clear: both;
      }
    }
  }
`
