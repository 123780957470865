import React from 'react'

function CloseIcon({height = 30, width = 30, fontColor = '#000', strokeWidth = 2}) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 14.498 2.001 2.5M2 14.498 13.999 2.5"
        stroke={fontColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default CloseIcon
