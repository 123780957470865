import noop from 'lodash/noop'
import {useCallback, useMemo} from 'react'

import Responsive from '../../../HOC/Responsive'
import OptimizedImage from '../../OptimizedImage'
import {StyledAvatar, StyledMenu} from './UserMenu.styles'

function UserMenu({
  user,
  onLoginClick = noop,
  label,
  loggedInLabel,
  children,
  leadingIcon = '',
  isMobile = false,
}) {
  const {isLoggedIn = false, firstName = ''} = user || {}

  const onLoginBtnClick = useCallback(
    (e) => {
      e.preventDefault()
      onLoginClick()
    },
    [onLoginClick]
  )
  const newLabel = useMemo(() => {
    return (
      <StyledMenu>
        {!!leadingIcon && (
          <StyledAvatar>
            <OptimizedImage
              source={leadingIcon}
              aspectWidth="16"
              aspectHeight="16"
              desktopViewWidth="TINY"
              mobileViewWidth="TINY"
              altText="user-avatar"
              loading="lazy"
              customClassName="avatar-img"
            />
          </StyledAvatar>
        )}
        <span>{`${loggedInLabel}, ${firstName}`}</span>
      </StyledMenu>
    )
  }, [loggedInLabel, firstName])

  if (isLoggedIn) {
    return <>{children({customLabel: newLabel})}</>
  }

  if (!isMobile) {
    return (
      <li key={label} className="linkItems">
        <a className="link" href="#" onClick={onLoginBtnClick}>
          {label}
        </a>
      </li>
    )
  }

  return (
    <li key={label} className="linkItem">
      <a className="link" href="#" onClick={onLoginBtnClick}>
        {label}
      </a>
    </li>
  )
}

export default Responsive(UserMenu)
