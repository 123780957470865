import React from 'react'

import {TruecallerLoadingContainer} from './TruecallerLoading.styles'
import Spinner from '../../../ComponentsV2/common/Spinner'

function TruecallerLoading(props) {
  const {className = ''} = props

  return (
    <TruecallerLoadingContainer className={className}>
      <div className="loading-container">
        <Spinner size={30} />
      </div>
    </TruecallerLoadingContainer>
  )
}

export default TruecallerLoading
