import React, {useCallback, useMemo, useState} from 'react'
import HeaderBrandLogo from './Logo'
import NavLinks from './NavLinks'
import MobileNavLinks from './MobileNavLinks'
import Responsive from '../../HOC/Responsive'
import IconsMenu from './IconsMenu'
import noop from 'lodash/noop'
import {NavbarContainer, StyledSearchWrapper} from './styles'
import {useEffect} from 'react'
import {useRouter} from 'next/router'

import SearchBar from './SearchBar'

const NavBarConstants = {
  login: 'login',
}

function Navbar({
  isMobile,
  showSearchBar,
  navData = {},
  user = {},
  showSearchModal = false,
  handleNavigateTo = noop,
  cartItemCount = 0,
  navbarActions = noop,
  onHamburgerClick = noop,
  disableBodyScroll = noop,
  enableBodyScroll = noop,
  whatsappData,
}) {
  const {
    backgroundColor = '#000',
    fontColor = '#fff',
    logo = {},
    headerMenuOptionsV2: headerMenuOptions = [],
    rightMenuOptions = [],
  } = navData
  const {isLoggedIn = false} = user
  const {asPath} = useRouter()

  const [hamburgerOpen, setHamburgerOpen] = useState(false)

  const handleMenuItemClicked = useCallback(
    (navItem) => {
      handleNavigateTo(navItem, isMobile)
      if (isMobile) setHamburgerOpen(false)
    },
    [handleNavigateTo, isMobile]
  )

  const handleLogin = useCallback(() => {
    navbarActions({action: NavBarConstants.login})
    if (isMobile) setHamburgerOpen(false)
  }, [navbarActions, isMobile])

  const toggleHamburger = useCallback(() => {
    setHamburgerOpen((hamburgerOpen) => !hamburgerOpen)
    onHamburgerClick()
  }, [onHamburgerClick])

  useEffect(() => {
    if (hamburgerOpen) {
      disableBodyScroll()
    } else {
      if (!showSearchModal) enableBodyScroll()
    }
  }, [disableBodyScroll, enableBodyScroll, hamburgerOpen, showSearchModal])

  const handleIconClicked = useCallback(
    (icon) => {
      navbarActions(icon)
      if (isMobile) setHamburgerOpen(false)
    },
    [navbarActions, isMobile]
  )

  const searchBarData = useMemo(() => {
    return rightMenuOptions.filter((icon) => {
      return icon.label === 'search'
    })
  }, [rightMenuOptions])

  const showSearchBarWithConfigText = useMemo(() => {
    const {isSearchBarWithConfigText = false} = searchBarData[0] || {}
    return isSearchBarWithConfigText
  }, [searchBarData])

  const isSearchBarSticky = useMemo(() => {
    const {isSearchBarWithConfigText = false, SearchBarStickyRegex = ''} =
      searchBarData[0] || {}

    if (!asPath || !SearchBarStickyRegex) {
      return false
    }
    const regExForSticky = new RegExp(SearchBarStickyRegex)

    const [path] = asPath.split('?')
    const isSearchBarWithConfigTextSticky = regExForSticky.test(path)

    return isSearchBarWithConfigText && isSearchBarWithConfigTextSticky
  }, [searchBarData, asPath])

  const isSearchBarShown = useMemo(() => {
    return showSearchBar && showSearchBarWithConfigText
  }, [showSearchBar, showSearchBarWithConfigText])

  return (
    <>
      <NavbarContainer
        id="navbar-container"
        backgroundColor={backgroundColor}
        isSearchBarShown={isSearchBarShown}
        fontColor={fontColor}
        isMobile={isMobile}
      >
        <div className="nav-top-section">
          <div className="left-section">
            {isMobile && (
              <MobileNavLinks
                isSearchBarShown={isSearchBarShown}
                isSearchBarSticky={isSearchBarSticky}
                headerLinks={headerMenuOptions}
                handleMenuItemClicked={handleMenuItemClicked}
                handleLogin={handleLogin}
                fontColor={fontColor}
                hamburgerOpen={hamburgerOpen}
                toggleHamburger={toggleHamburger}
                user={user}
              />
            )}
            <HeaderBrandLogo
              {...logo}
              isMobile={isMobile}
              handleMenuItemClicked={handleMenuItemClicked}
            />
          </div>
          <div className="middle-section">
            {!isMobile && (
              <NavLinks
                headerLinks={headerMenuOptions}
                isLoggedIn={isLoggedIn}
                handleLogin={handleLogin}
                handleMenuItemClicked={handleMenuItemClicked}
                fontColor={fontColor}
                user={user}
              />
            )}
          </div>
          <div className="right-section">
            <IconsMenu
              isSearchBarShown={isSearchBarShown}
              iconsMenuOptions={rightMenuOptions}
              handleIconClicked={handleIconClicked}
              cartItemCount={cartItemCount}
              user={user}
              whatsappData={whatsappData}
            />
          </div>
        </div>
      </NavbarContainer>
      <StyledSearchWrapper
        isSearchBarShown={isSearchBarShown}
        isSearchBarSticky={isSearchBarSticky}
      >
        {isMobile && isSearchBarShown && (
          <SearchBar
            searchBarData={searchBarData[0]}
            handleIconClicked={handleIconClicked}
          />
        )}
      </StyledSearchWrapper>
    </>
  )
}

export default Responsive(Navbar)
