import {useEffect} from 'react'
import {docCookies} from 'src/utils/cookie'

const REPEAT_USER_COOKIE_NAME = `repeat_user`

export function useAddRepeatUserCookie(
  isUserHydration: boolean,
  isLoggedIn: boolean,
  repeatUser: boolean
): void {
  const repeatUserCookieValue = repeatUser ? 'Y' : 'N'
  const {setItem: setRepeatUserCookie} = docCookies

  useEffect(() => {
    if (!isUserHydration && isLoggedIn) {
      setRepeatUserCookie({
        cookieName: REPEAT_USER_COOKIE_NAME,
        cookieValue: repeatUserCookieValue,
      })
    }
  }, [isUserHydration, isLoggedIn, repeatUser])
}
