export const NAVBAR_ANALYTICS = {
  MENULINK_CLICKED: 'menuLinkClicked',
  HAMBURGER_MENU: 'HamburgerMenu',
  NAVBAR: 'NavBar',
  HAMBURGER_CLICK: 'hamburgerClick',
  SEARCH_CLICK: 'searchClick',
  CART_FROM_SEARCH_CLICK: 'searchCartClick',
}

export const NAVBAR_GENERAL = {
  PROFILE: 'profile',
  LOGIN: 'login',
  HTTP: 'http',
  SEARCH: 'search',
  LINK: 'LINK',
  SPA_LINK: 'SPA_LINK',
  STORE_REF_AND_NAV: 'STORE_REF_AND_NAV',
}
