import noop from 'lodash/noop'
import React, {useCallback, useMemo} from 'react'

import {getLinkToRender} from '../../../../utils'
import Dropdown from '../Dropdown'
import UserMenu from '../UserMenu'
import {NavLinksContainer} from './styles'

function NavLinks({
  headerLinks = [],
  user = {},
  handleMenuItemClicked = noop,
  handleLogin = noop,
  fontColor = '#fff',
}) {
  const {isLoggedIn = false} = user
  const filteredHeaderLinks = useMemo(
    () => headerLinks.filter((item) => !item.hiddenOnDesktop),
    [headerLinks]
  )

  const handleNavigation = useCallback((e, item) => {
    e.preventDefault()
    handleMenuItemClicked(item)
  }, [])

  return (
    <NavLinksContainer fontColor={fontColor}>
      <ul className="navLinksWrapper">
        {filteredHeaderLinks.map((item, index) => {
          const {
            type,
            link = '',
            dropdown = '',
            label = '',
            leadingIcon = '',
            loggedInLabel = '',
          } = item
          if (type === 'AUTH') {
            return (
              <UserMenu
                user={user}
                onLoginClick={handleLogin}
                loggedInLabel={loggedInLabel}
                label={label}
                leadingIcon={leadingIcon}
                key={index}
              >
                {({customLabel}) => {
                  return (
                    <li className="hasDropdown linkItems" key={label}>
                      {customLabel}
                      <Dropdown
                        dropdownItems={dropdown}
                        handleMenuItemClicked={handleMenuItemClicked}
                      />
                    </li>
                  )
                }}
              </UserMenu>
            )
          }
          if (link) {
            const linkToRender = getLinkToRender(link)
            return (
              <li key={label} className="linkItems">
                <a
                  className="link"
                  href={linkToRender}
                  onClick={(e) => {
                    handleNavigation(e, item)
                  }}
                >
                  {label}
                </a>
              </li>
            )
          }

          if (dropdown) {
            return (
              <li className="hasDropdown linkItems" key={label}>
                <span className="dropdown-label link">{label}</span>
                <Dropdown
                  dropdownItems={dropdown}
                  handleMenuItemClicked={handleMenuItemClicked}
                />
              </li>
            )
          }

          return null
        })}
      </ul>
    </NavLinksContainer>
  )
}

export default React.memo(NavLinks)
