import React from 'react'

function MenuIcon({height = 30, width = 30, fontColor = '#000'}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.725 27.07c.146.253.31.563.49.93.18.367.363.753.55 1.16.186.4.37.81.55 1.23.186.413.356.797.51 1.15l.5-1.15c.18-.42.363-.83.55-1.23.186-.407.37-.793.55-1.16.18-.367.343-.677.49-.93h1.14a65.098 65.098 0 0 1 .31 3.37c.046.593.086 1.193.12 1.8.04.6.073 1.187.1 1.76h-1.24c-.027-.807-.06-1.637-.1-2.49a47.499 47.499 0 0 0-.16-2.48c-.074.153-.16.337-.26.55-.1.213-.207.447-.32.7-.114.247-.23.503-.35.77a110.777 110.777 0 0 0-.64 1.48l-.24.55h-.94a69.36 69.36 0 0 1-.24-.56c-.094-.22-.197-.453-.31-.7-.107-.253-.22-.513-.34-.78a29.26 29.26 0 0 0-.34-.77 98.749 98.749 0 0 0-.58-1.24c-.074.8-.13 1.627-.17 2.48-.034.853-.064 1.683-.09 2.49h-1.24c.026-.573.056-1.167.09-1.78l.12-1.82c.046-.607.096-1.193.15-1.76.053-.567.11-1.09.17-1.57h1.17Zm6.978 4.34c0-.46.066-.863.2-1.21.14-.347.323-.633.55-.86.226-.233.486-.407.78-.52.293-.12.593-.18.9-.18.72 0 1.28.223 1.68.67.406.447.61 1.113.61 2 0 .067-.004.143-.01.23 0 .08-.004.153-.01.22h-3.45c.033.42.18.747.44.98.266.227.65.34 1.15.34.293 0 .56-.027.8-.08a3.3 3.3 0 0 0 .58-.17l.16.99a2.24 2.24 0 0 1-.28.11 3.673 3.673 0 0 1-.4.09 4.51 4.51 0 0 1-1.01.11c-.46 0-.86-.067-1.2-.2-.34-.14-.62-.33-.84-.57a2.39 2.39 0 0 1-.49-.86 3.56 3.56 0 0 1-.16-1.09Zm3.51-.54c0-.167-.024-.323-.07-.47a1.047 1.047 0 0 0-.21-.39.845.845 0 0 0-.33-.26.976.976 0 0 0-.46-.1c-.187 0-.35.037-.49.11-.14.067-.26.157-.36.27a1.311 1.311 0 0 0-.22.39 2.11 2.11 0 0 0-.11.45h2.25Zm2.493-1.93a8.104 8.104 0 0 1 2.15-.28c.426 0 .783.06 1.07.18.286.113.513.277.68.49.173.207.293.46.36.76.073.293.11.617.11.97V34h-1.21v-2.75c0-.28-.02-.517-.06-.71a1.13 1.13 0 0 0-.18-.48.653.653 0 0 0-.34-.27c-.14-.06-.314-.09-.52-.09-.154 0-.314.01-.48.03a9.09 9.09 0 0 0-.37.05V34h-1.21v-5.06Zm10.14 4.9c-.234.06-.538.12-.91.18-.374.067-.784.1-1.23.1-.42 0-.774-.06-1.06-.18a1.74 1.74 0 0 1-.68-.5 2.007 2.007 0 0 1-.36-.76 4.12 4.12 0 0 1-.11-.98v-2.93h1.21v2.74c0 .56.08.96.24 1.2.166.24.452.36.86.36.146 0 .3-.007.46-.02.166-.013.29-.03.37-.05v-4.23h1.21v5.07Z"
        fill={fontColor}
      />
      <g clipPath="url(#a)" stroke="#000" strokeWidth={2}>
        <rect x={3} y={1} width={22} height={22} rx={5} fill="#fff" />
        <path strokeLinecap="round" d="M8 12h12M8 7h12M8 17h12" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(2)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default MenuIcon
