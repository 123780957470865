import styled from 'styled-components'

export const TruecallerLoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;

  .loading-container {
    height: 40px;
    width: 40px;
    background-color: var(--brand-primary-white-color);
    border-radius: 8px;
    padding: 5px;
  }
`
