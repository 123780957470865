interface IWhatsappConfig {
  text: string
  phoneNumber: string
  isHidden: boolean
  replacementText?: string
}

export function getWhatsappLink(whatsappConfig: IWhatsappConfig) {
  const {
    text = '',
    phoneNumber = '',
    replacementText = '',
  } = whatsappConfig || {}
  const PREFIX = `https://api.whatsapp.com/send/?phone=${phoneNumber}&type=phone_number&app_absent=0&text=`
  let whatsappText = text.replace('__', replacementText)
  const SUFFIX = encodeURIComponent(whatsappText)
  return `${PREFIX}${SUFFIX}`
}
